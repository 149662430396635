<template>
    <v-container fluid class="wave">
        <v-row class="sea-background" style="background-color: #dddddf;" justify="start" align="start">
            <!-- <v-col cols="1">
                <v-img contain height="40px" :src="require('@/assets/palm.png')" style="z-index: 1000;" />
            </v-col>
            <v-col cols="1">
                <v-img contain height="40px" :src="require('@/assets/flamingo.png')" style="z-index: 1000;" />
            </v-col> -->
            <v-col cols="12"> <!--//cols="8">-->
                <span class="custom-orange-text mx-3" style="font-size: 18px;">#backinthegame follow us on social!</span>
                <v-icon class="custom-orange-text" style="padding-bottom: 10px;" @click="openSocial('facebook')"> mdi-facebook</v-icon>
                <v-icon class="custom-orange-text mx-5" style="padding-bottom: 10px;" @click="openSocial('instagram')"> mdi-instagram</v-icon>
                <v-icon class="custom-orange-text mr-5" style="padding-bottom: 10px;" @click="openSocial('youtube')"> mdi-youtube </v-icon>
                <v-img
                    :width="!$vuetify.breakpoint.mobile ? '20px' : '20px'"
                    contain
                    style="display: inline-flex; cursor: pointer;"
                    :src="require('@/assets/tiktok.svg')"
                    @click="openSocial('tiktok')"
                />
            </v-col>
            <!-- <v-col cols="1">
                <v-img contain height="40px" :src="require('@/assets/sun-umbrella.png')" style="z-index: 1000;" />
            </v-col>
            <v-col cols="1">
                <v-img contain height="40px" :src="require('@/assets/sun.png')" style="z-index: 1000;" />
            </v-col> -->
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        methods: {
            openSocial(social) {
                if (social === 'facebook') {
                    window.open('https://www.facebook.com/backinthegamegr', '_blank')
                } else if (social === 'instagram') {
                    window.open('https://www.instagram.com/backinthegame_2012/', '_blank')
                } else if (social === 'youtube') {
                    window.open('https://www.youtube.com/@BackIntheGameBasketball', '_blank')
                } else if (social === 'tiktok') {
                    window.open('https://www.tiktok.com/@backinthegame2012', '_blank')
                }
            }
        }
    }
</script>

<style scoped>
/*
.sea-background {
    background-color: #91dcef;
}

.wave {
    display: block;
    position: relative;
    height: 50px;
    width: 100%;
    background: #f2d16b;
    transform: scale(1, 1);
}

.wave::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 300px;
    background-color: #f2d16b;
    right: -25%;
    top: 20px;
}

.wave::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 310px;
    background-color: #f2d16b;
    left: -25%;
    top: -240px;
    clip-path: ellipse(100% 15% at -15% 100%);
} */
</style>
