<template>
    <v-container fluid class="px-0 mx-0 py-0 my-0" style="position: sticky; top: 0; z-index: 10;">
        <v-row justify="center" class="custom-header mx-0 px-0">
            <v-col cols="7" sm="9" align="start">
                <v-row no-gutters align="center" justify="center">
                    <v-col cols="12" class="py-0 my-0">
                        <span class="custom-orange-text">{{ title }}</span>
                    </v-col>
                    <v-col cols="12" align="start">
                        <v-icon class="custom-orange-text" @click="openSocial('facebook')"> mdi-facebook</v-icon>
                        <v-icon class="custom-orange-text mx-5" @click="openSocial('instagram')"> mdi-instagram</v-icon>
                        <v-icon class="custom-orange-text mr-5" @click="openSocial('youtube')"> mdi-youtube </v-icon>
                        <v-img
                            :width="!$vuetify.breakpoint.mobile ? '20px' : '20px'"
                            contain
                            style="display: inline-flex; bottom: 5px; position: absolute;"
                            :src="require('@/assets/tiktok.svg')"
                            @click="openSocial('tiktok')"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="5" sm="3" align="start" class="mx-0 px-0 py-0 my-0">
                <v-row class="mx-0 px-0 py-0 my-0">
                    <v-col cols="5" align="end" class="mx-0 px-0">
                        <v-btn
                            icon
                            large
                            color="orange"
                            @click="$store.getters.getTeam ? $router.push({name: 'Team', params: { id: $store.getters.getTeam.id } }) : $router.push({name: 'Profile'})"
                        >
                            <v-img v-if="$store.getters.getTeam" contain width="50px" :src="`${assetsUrl}/${$store.getters.getTeam.logo}`" />
                            <v-icon v-else class="orange--text" large>mdi-account-group</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="2" align="center" class="pb-8 mx-0 px-0">
                        <v-divider
                            vertical
                            color="orange"
                            class="custom-divider"
                        />
                    </v-col>
                    <v-col cols="5" align="start" class="mx-0 px-0">
                        <v-btn
                            icon
                            large
                            color="orange"
                            @click="$store.getters.getPlayer ? $router.push({name: 'Player', params: { id: $store.getters.getPlayer.id } }) : $router.push({name: 'Profile'})"
                        >
                            <v-img v-if="$store.getters.getPlayer" width="50" contain :src="`${assetsUrl}/${$store.getters.getPlayer.image}`" class="rounded-xl" />
                            <v-icon v-else class="orange--text" large>mdi-account</v-icon>
                        </v-btn>
                        <v-row class="mx-0 px-0">
                            <v-col cols="12" align="end" class="mx-0 px-0">
                                <v-btn
                                    fab
                                    x-small
                                    class="custom-orange"
                                    to="/Profile"
                                >
                                    <v-icon class="white--text">mdi-cog</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                assetsUrl: 'https://storage.googleapis.com'
            }
        },
        methods: {
            openSocial(social) {
                if (social === 'facebook') {
                    window.open('https://www.facebook.com/backinthegamegr', '_blank')
                } else if (social === 'instagram') {
                    window.open('https://www.instagram.com/backinthegame_2012/', '_blank')
                } else if (social === 'youtube') {
                    window.open('https://www.youtube.com/@BackIntheGameBasketball', '_blank')
                } else if (social === 'tiktok') {
                    window.open('https://www.tiktok.com/@backinthegame2012', '_blank')
                }
            }
        }
    }
</script>

<style scoped>
.custom-header {
    font-weight: 600;
    font-size: 23px;
    background-color: white;
    color: orange;
    height: 80px;
}

.custom-divider {
    border-color: orange;
    border-width: 1px;
    height: 20px;
    margin: 0 10px 0 10px;
}
</style>
